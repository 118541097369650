
<template>
  <div>
      <v-card>
        <v-card-title style="word-break: break-word;">
          <div class="text-overline">{{item.title}}<span style="color: red;">{{item.required ? '*' : ''}}</span></div>
        </v-card-title>
        <v-card-text style="word-break: break-word;">
          <div class="text-caption">{{item.description}}</div>
            <v-select
              :items="item.values"
              :rules="item.required ? required : []"
              v-model="answer"
              @change="setItem"
            ></v-select>
            <v-text-field @change="setItem" v-if="item.other_value == 'option_and_input'" :disabled="answer != 'Outro'" color="primary" label="Sua resposta" v-model="other_value_input" :required="answer == 'Outro'"></v-text-field>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations';

export default {
  name: 'DropdownItem',
  mixins: [
    validations
  ],
  props: {
    item: {
      type: Object,
      default: {}
    },
    read_only: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    answer: null,
    other_value_input: null,
  }),
  methods: {
    checkOtherValue(){
      if(this.item.other_value != 'none_option' && this.item.values){
        this.item.values.indexOf('Outro', 1) <= 0 ? this.item.values.push('Outro') : ''
      }
    },
    setItem(Nv){
      let new_done_item = {
        index_order: this.item.index_order,
        item_id: this.item.id,
        title: this.item.title,
        answer: this.answer == 'Outro' ? this.other_value_input : this.answer
      }
      let has_item_id = this.done_items_attributes.findIndex(done_item => {
        return done_item.item_id == this.item.id;
      }) < 0 ? false : true
      if(has_item_id){
        this.editInDoneItemsAttributes(new_done_item)
      }else{
        this.addToDoneItemsAttributes(new_done_item)
      }
    },
    ...mapActions({
      addToDoneItemsAttributes: 'DoneForm/addToDoneItemsAttributes',
      editInDoneItemsAttributes: 'DoneForm/editInDoneItemsAttributes'
    })
  },
  computed: {
    ...mapState({
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    })
  },
  mounted() {
    this.checkOtherValue()
  },
}
</script>

<style scoped>

</style>